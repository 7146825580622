import { Errors } from '../../apis/client-axios';
import { sentToSlack } from '../../util/function-util';
import NotificationError from '../notification/NotificationError';
import { ErrorMessage } from './error.message';

export async function CustomHandleError(error: any, fullError?: any) {
  if (error?.message === 'CAMPAIGN_CODE_EXPIRED') {
    NotificationError({ content: ErrorMessage.CAMPAIGN_CODE_EXPIRED });
  } else if ((error?.message as string)?.includes('CAMPAIGN_CODE')) {
    NotificationError({ content: ErrorMessage.CAMPAIGN_CODE_INVALID });
  } else if (error?.statusCode === 500 || error?.statusCode === 501) {
    NotificationError({ content: ErrorMessage[500] });
  } else if (error?.statusCode === 404) {
    NotificationError({ content: ErrorMessage[404] });
  } else if (error?.statusCode === 403) {
    NotificationError({ content: ErrorMessage[403] });
  } else if (error?.statusCode === 401) {
    NotificationError({ content: ErrorMessage[401] });
  } else if (error?.statusCode === 406) {
    NotificationError({ content: ErrorMessage[406] });
  } else {
    if (error?.extendsMessage) {
      const extendsMessage: Errors | Errors[] = error.extendsMessage;
      const errorMessage: string = Array.isArray(extendsMessage)
        ? extendsMessage.find((item) => item?.SeverityCode === 'Error')?.LongMessage || 'Error occurred'
        : extendsMessage?.LongMessage
          ? extendsMessage?.LongMessage
          : error.extendsMessage;

      NotificationError({
        content: ErrorMessage?.[errorMessage] || ErrorMessage?.[error?.message] || ErrorMessage[500],
      });
      if (
        (process.env.REACT_APP_ENVIRONMENT == 'staging' || process.env.REACT_APP_ENVIRONMENT == 'production') &&
        !fullError?.response?.config?.url.includes('auth/user/login') &&
        !fullError?.response?.config?.headers?.Authorization?.include('null')
      ) {
        const msg = `
          API RETURN ERROR IN ${process.env.REACT_APP_ENVIRONMENT}
          method: ${fullError?.response?.config?.method} \n
          url: ${fullError?.response?.config?.url} \n
          token: ${fullError?.response?.config?.headers?.Authorization} \n
          data: ${fullError?.response?.config?.data} \n
          message: ${error.extendsMessage}
        `;
        sentToSlack(msg);
      }
    } else {
      NotificationError({ content: ErrorMessage?.[error?.message] ?? ErrorMessage[500] });
    }
  }
}
